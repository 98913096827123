import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Card, CardBody, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import { GET_SINGLE_TEMPLATE } from "../../Pages/settings/gql";
import { EmailModalInvoiceAttachmentProps } from "../../interfaces";
import EmailDraft from "../EmailDraft";
import CustomButton from "../layout/CustomButton";
import { EMAIL_MUTATION, GET_ALL_COMPANY_CONTACTS_EMAIL } from "./gql";

const EmailModal: FC<EmailModalInvoiceAttachmentProps> = ({ invoicePreSelectEmail, companyId,
  isOpen, toggle, refetch, startDate, ccEmails, toEmail, accountManagerEmail, id, invoiceId, emailContent, fileName, source }) => {
  const [emailMutation, { loading }] = useMutation(EMAIL_MUTATION, {
    onCompleted: () => {
      toast.success("email sent successfully")
      toggle()
    }
  })
  const [bodyContent, setBodyContent] = useState<string | null>();
  const [subject, setSubject] = useState<string | null>();
  const [companyContacts, setCompanyContacts] = useState<{ label: string, value: string }[]>([]);
  const [additionalFiles, setAdditionalFiles] = useState<{ source: string, fileName: string }[]>([]);

  const { register, watch } = useForm()
  const { resendEmail } = watch()


  const [getTemplateQuery, { data }] = useLazyQuery(GET_SINGLE_TEMPLATE, {
    // onCompleted: () => console.log("data ", data),
    onError: (err) => toast.error(err?.message)
  })

  const [getAllContactsEmails] = useLazyQuery(GET_ALL_COMPANY_CONTACTS_EMAIL, {
    onCompleted: (data) => setCompanyContacts(data?.getAllCompanyContactsEmails.filter((item, index, self) =>
      index === self.findIndex((e) => e.value === item.value)
    ))
  })

  const [mailContact, setMailContact] = useState<{ to: string[], ccContacts: string[]; bccContacts: string[] }>({
    to: [],
    ccContacts: [],
    bccContacts: [],
  });

  const [newMailContact, setNewMailContact] = useState<{ to: string[], ccContacts: string[]; bccContacts: string[] }>({
    to: [],
    ccContacts: [],
    bccContacts: [],
  });

  useEffect(() => {
    getTemplateQuery({
      variables: {
        module: resendEmail ? 'Invoice Aged Email' : 'Invoice Email'
      },
    })
  }, [resendEmail])

  useEffect(() => {
    if (companyId) {
      getAllContactsEmails({ variables: { companyId } })
    }
  }, [companyId])
  useEffect(() => {
    const preSelectCCEmails = invoicePreSelectEmail?.filter((item) => item?.emailType === "CC_EMAIL")?.map((item) => item?.email)
    const preSelectToEmails = invoicePreSelectEmail?.filter((item) => item?.emailType === "TO_EMAIL")?.map((item) => item?.email)
    const preSelectBCCEmails = invoicePreSelectEmail?.filter((item) => item?.emailType === "BCC_EMAIL")?.map((item) => item?.email)
    if (isOpen) {
      setMailContact({
        to: (Array.from(
          new Set([...preSelectToEmails, toEmail].filter(Boolean).reverse())
        ).reverse()),
        ccContacts: (Array.from(
          new Set([...preSelectCCEmails, ...ccEmails, accountManagerEmail, "johnnyrenaudo@realrepp.com"]
            .filter(Boolean).reverse())
        ).reverse()),
        bccContacts: (Array.from(
          new Set(preSelectBCCEmails.filter(Boolean).reverse())
        ).reverse())
      })
    } else {
      setSubject('')
      setBodyContent('')
    }
  }, [isOpen])

  useEffect(() => {
    if (data) {
      setBodyContent(
        data?.getTemplateByModule?.template
          .replaceAll("{{clientName}}", emailContent?.clientName)
          .replaceAll("{{candidateFullName}}", emailContent?.candidateFullName)
          .replaceAll("{{placementJobTitle}}", emailContent?.placementJobTitle)
          .replaceAll("{{jobCity}}", emailContent?.jobCity)
          .replaceAll("{{jobStateCode}}", emailContent?.jobStateCode)
          .replaceAll("{{invoiceId}}", invoiceId)
          .replaceAll("{{agedDays}}", moment().diff(moment(startDate), 'days'))

      )
      setSubject(data?.getTemplateByModule?.subject
        .replaceAll("{{invoiceId}}", invoiceId)
        .replaceAll("{{placementJobTitle}}", emailContent?.placementJobTitle)
        .replaceAll("{{candidateFullName}}", emailContent?.candidateFullName)
        .replaceAll("{{agedDays}}", moment().diff(moment(startDate), 'days'))
        .replaceAll(" - {{billBoxTag}}", emailContent?.billBoxTag ? ` - ${emailContent?.billBoxTag}` : '')
      )
    }
  }, [data, isOpen])

  const handleSubmit = async () => {
    try {
      await emailMutation({
        variables: {
          input: {
            toContacts: mailContact.to,
            id,
            bodyContent: bodyContent?.replaceAll('<p>', '').replaceAll('</p>', '<br>'),
            subject,
            ccContacts: mailContact.ccContacts,
            bccContacts: mailContact.bccContacts,
            toContactsNewCreated: newMailContact?.to,
            ccContactsNewCreated: newMailContact?.ccContacts,
            bccContactsNewCreated: newMailContact?.bccContacts,
            emailType: resendEmail ? 'Invoice Aged Email' : 'Invoice Email'
          },
          additionalFiles
        }
      })
      refetch()
    } catch (error) {
      toast.error("something went wrong")
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="for-modal-styling">
      <ModalBody>
        <div id="submittedEmail" className="writeup-wrap submission-package">
          <Card className="border-0">
            <CardBody className="p-0">
              <div className="dollar-addon-btn">
                <div className="d-flex my-3 justify-content-between">
                  <Label className="text-14  ">Email</Label>
                  <div className="d-flex align-items-center">
                    <span className="switchLabel">Initial Sending</span>
                    <label className="switch m-0 mx-2">
                      <Input type="checkbox" name="resendEmail" innerRef={register} />
                      <span className="slider"></span>
                    </label>
                    <span className="switchLabel">Aged Invoice</span>
                  </div>
                </div>
                <EmailDraft
                  fileName={fileName} source={source} setMailContact={setMailContact} setSubject={setSubject} setBodyContent={setBodyContent}
                  selectedFiles={additionalFiles} setSelectedFiles={setAdditionalFiles} setNewMailContact={setNewMailContact}
                  bodyContent={bodyContent} mailContact={mailContact} subject={subject} newMailContact={newMailContact}
                  companyContacts={companyContacts}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </ModalBody>

      <ModalFooter>
        <button type="button" onClick={toggle} className="buttonPrimary mr-3">
          Cancel
        </button>
        <CustomButton
          loading={loading}
          disabled={loading || !mailContact?.to?.length}
          buttonClick={handleSubmit}
          buttonText="Send Email"
        />
      </ModalFooter>
    </Modal>)

}
export default EmailModal